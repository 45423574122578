.gridCell{
    color: #8392ab !important;
    text-transform: none !important;
    font-size: 13.6px !important;
    font-weight: 400 !important;
}
.gridCell :hover {
      color: rgba(5, 152, 236, 0.637) !important;
      font-size: 14.6px !important;
    }

    .MuiDataGrid-booleanCell{
        color: #8392ab !important;
        font-size: 13.6px !important;
        font-weight: 400 !important;
    }
    .css-13xfq8m-MuiTabPanel-root {
        width: 100% !important;
    }
    .css-19kzrtu {
        width: 100% !important;
        padding: 24px;
    }
    .css-1e7c2qr-MuiToolbar-root-MuiTablePagination-toolbar
 .MuiTablePagination-actions {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: -1rem;
    margin-left: 20px;
}